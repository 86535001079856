<template>
  <div class="d-flex d-flex-c d-flex-between flex-1">
    <div
      class="d-flex d-flex-r d-flex-between d-align-content-start section one"
    >
      <chart-wrapper
        label="Public impressions"
        wrapper-type="small-c"
        :chart-config="publicImpressionsConf"
      />
      <chart-wrapper
        class="ml-1"
        label="Non public impressions"
        wrapper-type="small-c"
        :chart-config="nonPublicImpressionsConf"
      />
      <chart-wrapper
        class="ml-1"
        label="New likes"
        wrapper-type="small-c"
        :chart-config="newLikesConf"
      />
      <chart-wrapper
        class="ml-1"
        label="Followers"
        wrapper-type="small-c"
        :chart-config="currentFollowersConf"
      />
    </div>
    <div class="d-flex d-flex-r d-flex-between d-align-content-start section">
      <chart-wrapper
        label="Tweet activity"
        chart-type="heatmap"
        wrapper-type="large f-height"
        :chart-config="tweetActivity"
      />
    </div>
    <div
      class="d-flex d-flex-r d-flex-between d-align-content-start section two"
    >
      <chart-wrapper
        label="Public impressions"
        chart-type="area"
        wrapper-type="large f-height"
        :chart-config="publicImpressionsSeries"
      />
      <chart-wrapper
        class="ml-1"
        chart-type="line"
        label="Non public impressions"
        wrapper-type="large f-height"
        :chart-config="nonPublicImpressionsSeries"
      />
    </div>
    <div
      class="d-flex d-flex-r d-flex-between d-align-content-start section two"
    >
      <chart-wrapper
        label="Public likes"
        chart-type="area"
        wrapper-type="large f-height"
        :chart-config="likesSeries"
      />
      <chart-wrapper
        class="ml-1"
        chart-type="line"
        label="Retweets"
        wrapper-type="large f-height"
        :chart-config="retweetSeries"
      />
    </div>

    <div
      class="d-flex d-flex-r d-flex-between d-align-content-start section two"
    >
      <chart-wrapper
        label="Replies"
        chart-type="area"
        wrapper-type="large f-height"
        :chart-config="replySeries"
      />
      <chart-wrapper
        class="ml-1"
        chart-type="line"
        label="Bookmarks"
        wrapper-type="large f-height"
        :chart-config="bookmarkSeries"
      />
    </div>
  </div>
</template>

<script>
import dayjs from "@/utils/timezone";
import { areaChartOpts, smallAreaChartOpts } from "@/utils/chart";
import { timeTable } from "@/utils/constants";

export default {
  name: "TwitterOverview",
  components: {
    ChartWrapper: () =>
      import("@/components/").then((res) => {
        return res.ChartWrapper;
      }),
  },
  props: {
    metrics: { type: Array, default: () => [] },
  },
  computed: {
    publicImpressionsConf() {
      const impressions =
        (this.metrics.filter(
          (metric) => metric.type === "public_impression_count",
        ) || [])[0] || {};

      const { data = [] } = impressions;

      const count = data.reduce((acc, curr) => {
        return acc + curr.count;
      }, 0);

      return {
        count,
      };
    },
    nonPublicImpressionsConf() {
      const reach =
        (this.metrics.filter(
          (metric) => metric.type === "non_public_impression_count",
        ) || [])[0] || {};

      const { data = [] } = reach;

      const count = data.reduce((acc, curr) => {
        return acc + curr.count;
      }, 0);

      return {
        count,
      };
    },
    newLikesConf() {
      const newLikes =
        (this.metrics.filter((metric) => metric.type === "public_like_count") ||
          [])[0] || {};

      const { data = [] } = newLikes;

      const count = data.reduce((acc, curr) => {
        return acc + curr.count;
      }, 0);

      return {
        count,
      };
    },
    currentFollowersConf() {
      const followersMetrics =
        (this.metrics.filter((metric) => metric.type === "profile_info") ||
          [])[0] || {};

      const { data = [] } = followersMetrics;

      const count = (data[0] || {}).followers_count || 0;

      return {
        count,
      };
    },
    tweetActivity() {
      const tweetTimeMetrics =
        (this.metrics.filter((metric) => metric.type === "tweet_time") ||
          [])[0] || {};

      const { data = [] } = tweetTimeMetrics;

      const days = [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday",
      ];

      const series = days
        .reduce((acc, curr) => {
          return acc.concat({
            name: curr,
            data: data.reduce((a, c) => {
              if (dayjs(c.date).format("dddd") === curr) {
                Object.entries(c.count).forEach(([k, j], i) => {
                  a[i] = {
                    x: k,
                    y: ((a[i] || {}).y || 0) + j,
                  };
                });
              }
              return a;
            }, []),
          });
        }, [])
        .map((serie) => {
          return {
            name: serie.name,
            data: serie.data.map((d) => {
              return { x: d.x, y: Math.floor(d.y / serie.data.length) };
            }),
          };
        });

      return {
        series,
        chartOptions: {
          dataLabels: {
            enabled: false,
          },
          colors: ["#008FFB"],
          xaxis: {
            tickAmount: 12,
            labels: {
              formatter: (value) => {
                return timeTable[value];
              },
            },
          },
        },
      };
    },
    publicImpressionsSeries() {
      const impressionMetrics =
        (this.metrics.filter(
          (metric) => metric.type === "public_impression_count",
        ) || [])[0] || {};

      const { data = [] } = impressionMetrics;

      const xaxis = data.map((d) => d.date);

      const seriesObj = data.reduce(
        (acc, curr) => {
          const j = xaxis.indexOf(curr.date);
          acc[j] = curr.count;

          return acc;
        },
        Array.from(xaxis).map((o) => 0),
      );

      const series = [
        {
          name: "Public impressions",
          data: seriesObj,
        },
      ];

      return {
        series,
        chartOptions: {
          ...areaChartOpts,
          xaxis: {
            ...areaChartOpts.xaxis,
            categories: xaxis,
          },
        },
      };
    },
    nonPublicImpressionsSeries() {
      const impressionMetrics =
        (this.metrics.filter(
          (metric) => metric.type === "non_public_impression_count",
        ) || [])[0] || {};

      const { data = [] } = impressionMetrics;

      const xaxis = data.map((d) => d.date);

      const seriesObj = data.reduce(
        (acc, curr) => {
          const j = xaxis.indexOf(curr.date);
          acc[j] = curr.count;

          return acc;
        },
        Array.from(xaxis).map((o) => 0),
      );

      const series = [
        {
          name: "Public impressions",
          data: seriesObj,
        },
      ];

      return {
        series,
        chartOptions: {
          ...areaChartOpts,
          xaxis: {
            ...areaChartOpts.xaxis,
            categories: xaxis,
          },
        },
      };
    },
    likesSeries() {
      const likesMetrics =
        (this.metrics.filter((metric) => metric.type === "public_like_count") ||
          [])[0] || {};

      const { data = [] } = likesMetrics;

      const xaxis = data.map((d) => d.date);

      const seriesObj = data.reduce(
        (acc, curr) => {
          const j = xaxis.indexOf(curr.date);
          acc[j] = curr.count;

          return acc;
        },
        Array.from(xaxis).map((o) => 0),
      );

      const series = [
        {
          name: "Public likes",
          data: seriesObj,
        },
      ];

      return {
        series,
        chartOptions: {
          ...areaChartOpts,
          xaxis: {
            ...areaChartOpts.xaxis,
            categories: xaxis,
          },
        },
      };
    },
    retweetSeries() {
      const retweetMetrics =
        (this.metrics.filter(
          (metric) => metric.type === "public_retweet_count",
        ) || [])[0] || {};

      const { data = [] } = retweetMetrics;

      const xaxis = data.map((d) => d.date);

      const seriesObj = data.reduce(
        (acc, curr) => {
          const j = xaxis.indexOf(curr.date);
          acc[j] = curr.count;

          return acc;
        },
        Array.from(xaxis).map((o) => 0),
      );

      const series = [
        {
          name: "Public likes",
          data: seriesObj,
        },
      ];

      return {
        series,
        chartOptions: {
          ...areaChartOpts,
          xaxis: {
            ...areaChartOpts.xaxis,
            categories: xaxis,
          },
        },
      };
    },
    replySeries() {
      const repliesMetrics =
        (this.metrics.filter(
          (metric) => metric.type === "organic_reply_count",
        ) || [])[0] || {};

      const { data = [] } = repliesMetrics;

      const xaxis = data.map((d) => d.date);

      const seriesObj = data.reduce(
        (acc, curr) => {
          const j = xaxis.indexOf(curr.date);
          acc[j] = curr.count;

          return acc;
        },
        Array.from(xaxis).map((o) => 0),
      );

      const series = [
        {
          name: "Organic replies",
          data: seriesObj,
        },
      ];

      return {
        series,
        chartOptions: {
          ...areaChartOpts,
          xaxis: {
            ...areaChartOpts.xaxis,
            categories: xaxis,
          },
        },
      };
    },
    bookmarkSeries() {
      const bookmarkMetrics =
        (this.metrics.filter(
          (metric) => metric.type === "public_bookmark_count",
        ) || [])[0] || {};

      const { data = [] } = bookmarkMetrics;

      const xaxis = data.map((d) => d.date);

      const seriesObj = data.reduce(
        (acc, curr) => {
          const j = xaxis.indexOf(curr.date);
          acc[j] = curr.count;

          return acc;
        },
        Array.from(xaxis).map((o) => 0),
      );

      const series = [
        {
          name: "Bookmarks",
          data: seriesObj,
        },
      ];

      return {
        series,
        chartOptions: {
          ...areaChartOpts,
          xaxis: {
            ...areaChartOpts.xaxis,
            categories: xaxis,
          },
        },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.section {
  > div {
    flex: 1;
  }
}
</style>
