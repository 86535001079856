import { formatChartDate } from "@/utils/timezone";
export const areaChartOpts = {
  grid: {
    xaxis: {
      lines: {
        show: false,
      },
    },
    yaxis: {
      lines: {
        show: true,
      },
    },
  },
  stroke: {
    curve: "smooth",
    width: 3,
  },
  plotOptions: {
    bar: {
      borderRadius: 6,
      columnWidth: "35%",
    },
  },
  dataLabels: {
    enabled: false,
  },
  fill: {
    type: "gradient",
    gradient: {
      opacityFrom: 0.5,
      opacityTo: 0.3,
      stops: [20, 100, 100, 100],
    },
  },
  xaxis: {
    categories: [],
    tickAmount: 6,
    labels: {
      formatter: function (value) {
        return formatChartDate(value, "MM-DD");
      },
      offsetY: 5,
    },
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
  },
  yaxis: {
    min: 0,
    tickAmount: 4,
    labels: {
      offsetX: -20,
    },
    axisBorder: {
      show: false,
    },
  },
  legend: {
    position: "top",
  },
};

export const smallAreaChartOpts = {
  grid: {
    show: false,
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: "smooth",
    width: 3,
  },
  fill: {
    type: "gradient",
    gradient: {
      opacityFrom: 0.5,
      opacityTo: 0.3,
      stops: [20, 100, 100, 100],
    },
  },
  xaxis: {
    categories: [],
    labels: {
      show: false,
      format: "dd/MM",
      formatter: function (value) {
        return formatChartDate(value, "MM-DD");
      },
    },
    axisTicks: {
      show: false,
    },
  },
  yaxis: {
    labels: {
      show: false,
    },
  },
};

export const barCharOpts = {
  grid: {
    xaxis: {
      lines: {
        show: false,
      },
    },
    yaxis: {
      lines: {
        show: false,
      },
    },
    stroke: {
      width: 50,
      colors: ["#000"],
    },
  },
  dataLabels: {
    enabled: false,
  },
  plotOptions: {
    bar: {
      borderRadius: 6,
      columnWidth: "20%",
      colors: {
        ranges: [
          {
            from: 0,
            to: 0,
            color: undefined,
          },
        ],
        backgroundBarColors: ["#f2f7ff"],
        backgroundBarOpacity: 1,
        backgroundBarRadius: 6,
      },
    },
  },
  xaxis: {
    categories: [],

    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
  },
  yaxis: {
    tickAmount: 1,
    labels: {
      // formatter: (value) => {
      //   return value.toFixed(0);
      // },
    },
  },
};
